@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

@font-face {
  font-family: "SteradianRegular";
  src: url("fonts/SteradianRegular.woff2") format("woff2"),
    url("fonts/SteradianRegular.woff") format("woff");
}

@font-face {
  font-family: "SteradianMedium";
  src: url("fonts/SteradianMedium.woff2") format("woff2"),
    url("fonts/SteradianMedium.woff") format("woff");
}

.swiper {
  padding-bottom: 20px !important;
}

.swiper-slide {
  background: none !important;
  align-items: flex-start !important;
}

.swiper-wrapper {
  padding-bottom: 10px;
  width: 100%;
}

.swiper-pagination {
  position: relative !important;
  top: 15px !important
}

.videoSwiper .swiper-wrapper .swiper-slide {
  height: 281px !important;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Change the scrollBar design (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Change the scrollBar design (Firefox) */
* {
  scrollbar-color: #888 #f0f0f0;
  scrollbar-width: thin;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #181717 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #161616);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
