.popup-header {
  display: flex;
  margin: 0 26px;
  padding-top: 35px;
  justify-content: flex-end;
}

section.login-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 50px;
  padding-top: 55px;
}

.avatar-container img {
  box-shadow: 0 2px 18px 0 rgb(36 19 19 / 9%);
  border-radius: 50%;
  width: 99px;
  height: 99px;
  /* object-fit: cover; */
}

.avatar-title {
  font-family: SteradianMedium;
  font-size: 24px;
  color: #000000;
  letter-spacing: -0.97px;
  margin-top: 16px;
}

.avatar-description {
  font-family: SteradianRegular;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.16px;
  line-height: 20px;
  margin-top: 21px;
  width: 314px;
}

.label {
  font-family: SteradianMedium;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.16px;
  line-height: 20px;
}

.login-input[type="password"],
.login-input[type="email"],
.login-input[type="text"] {
  border: 1px solid #ccb7b7 !important;
  border-radius: 9px !important;
  width: 314px !important;
  height: 45px !important;
  margin-top: 7px !important;
  background: transparent !important;
  padding-left: 21px !important;
  font-size: 18px;
  letter-spacing: -0.73px;
  line-height: 25px;
  width: 100%;



}

/* input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea:not(textarea.long-textarea) {
  background-color: transparent;
  border: none;
  font-family: SteradianRegular;
  font-size: 18px;
  color: #000000;
  letter-spacing: -0.73px;
  line-height: 25px;
  width: 100%;
  border-radius: 0px !important;
} */

.single-input {
  display: flex;
  flex-direction: column;
}

.button_father_password {
  position: relative !important;
}

#password-togglee {
  position: absolute;
  top: 68%;
  right: 0.7rem;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

#password-togglee svg {
  width: 1.5rem;
  height: 1.5rem;
}

.forgot-password-link {
  font-family: SteradianRegular;
  font-size: 15px;
  color: #5a5856;
  text-align: center;
  margin-top: 20px;
}

.forgot-password-link:active {
  font-family: SteradianRegular;
  font-size: 15px;
  color: #5a5856;
  text-align: center;
  margin-top: 20px;
}

.forgot-password-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
}
.forgot-password-link {
  font-family: SteradianRegular;
  font-size: 15px;
  color: #5a5856;
  text-align: center;
  margin-top: 20px;
}

.pop-btn-disabled {
  border: 0.8px solid #ccb7b7;
  border-radius: 6px;
  font-family: SteradianRegular;
  font-size: 15px;
  color: #ccb7b7;
  letter-spacing: 0.32px;
  text-align: center;
  margin-top: 43px;
  width: auto;
  padding: 0 31px;
  height: 45px;
  background: transparent;
}

.login-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-active {
  background: black !important;
  color: #ffffff !important;
  border: none;
}

.btn-active {
  background: #000000;
  border-radius: 6px;
  font-family: SteradianRegular;
  font-size: 15px;
  color: #ffffff;
  letter-spacing: 0.32px;
  text-align: center;
  border: none;
}

.inputs-container {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
}

.text-dander {
  color: #e63838;
  letter-spacing: -0.16px;
  line-height: 20px;
}

